<template>
  <ImpersonateUserButton
    :email="ownerEmail"
    :target-route="previewLink"
    tooltip="View in recordings"
  />
</template>

<script>
import ImpersonateUserButton from "@/components/ImpersonateUserButton"

export default {
  name: "OpenInDashRecordingsBtn",
  components: {
    ImpersonateUserButton,
  },
  props: {
    cameraExid: {
      type: String,
      default: "",
    },
    projectExid: {
      type: String,
      default: "",
    },
    ownerEmail: {
      type: String,
      default: "",
    },
    timestamp: {
      type: String,
      default: "",
    },
  },
  computed: {
    previewLink() {
      return `/projects/${this.projectExid}/${this.cameraExid}/recordings/snapshots/${this.timestamp}`
    },
  },
}
</script>
