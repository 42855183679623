<template>
  <div v-if="items.length > 1">
    <div class="font-weight-500">{{ title }}</div>
    <v-chip
      v-if="showAllOption"
      x-small
      outlined
      label
      class="mr-1 cursor-pointer"
      :color="allSelected ? 'primary' : 'secondary'"
      @click="toggleAll"
    >
      {{ allLabel }}
    </v-chip>
    <v-chip
      v-for="item in items"
      :key="itemKey(item)"
      x-small
      outlined
      label
      class="mr-1 cursor-pointer"
      :color="isSelected(item) ? 'primary' : 'secondary'"
      @click="toggleItem(item)"
    >
      {{ itemLabel(item) }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "ChipsToggleFilter",
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    allLabel: {
      type: String,
      default: "All",
    },
    showAllOption: {
      type: Boolean,
      default: true,
    },
    itemKey: {
      type: Function,
      required: true,
    },
    itemLabel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    allSelected() {
      return this.value.length === this.items.length
    },
  },
  methods: {
    toggleAll() {
      if (this.allSelected) {
        this.$emit("input", [])
      } else {
        this.$emit("input", this.items.map(this.itemKey))
      }
    },
    toggleItem(item) {
      const key = this.itemKey(item)
      if (this.value.includes(key)) {
        this.$emit(
          "input",
          this.value.filter((v) => v !== key)
        )
      } else {
        this.$emit("input", [...this.value, key])
      }
    },
    isSelected(item) {
      return this.value.includes(this.itemKey(item))
    },
  },
}
</script>
