export default [
  {
    text: "Thumbnail",
    value: "thumbnailUrl",
    sortable: false,
  },
  {
    text: "Plate Number",
    value: "plateNumber",
    sortable: true,
  },
  {
    text: "Capture Time",
    value: "captureTime",
    sortable: true,
  },
  {
    text: "Direction",
    value: "direction",
    sortable: true,
  },
  {
    text: "Vehicle Type",
    value: "vehicleType",
    sortable: true,
  },
]
