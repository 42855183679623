var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isHeadersRow)?_c('div',{staticClass:"row-item__header w-100 d-flex",style:({
    'min-height': `${_vm.height} !important`,
  })},_vm._l((_vm.headers),function(header){return _c('div',{key:header.value,staticClass:"pl-1 d-flex align-center justify-start column font-weight-500 caption font-weight-regular",class:{
      'column--sortable': header.sortable,
      'arrow-up': !_vm.isDesc,
      'arrow-down': _vm.isDesc,
      'is-selected': _vm.sortedColumn === header.value,
    },style:(_vm.cellStyle),on:{"click":function($event){return _vm.onSort(header.value)}}},[_vm._v("\n    "+_vm._s(header.text)+"\n  ")])}),0):_c('v-list-item',{staticClass:"row-item pa-0 caption",class:{
    'row-item--selected': _vm.isSelected,
    'row-item--errored': _vm.isErrored,
    'row-item--duplicate': _vm.isDuplicate,
    'row-item--not-processed': _vm.isNotProcessed,
  },style:({
    'min-height': `${_vm.height} !important`,
  }),on:{"click":_vm.onRowClick}},[_c('v-list-item-content',{staticClass:"py-0 h-100"},[_c('v-list-item-title',{staticClass:"h-100 d-flex align-center justify-start"},_vm._l((_vm.headers),function(header){return _c('div',{key:header.value,staticClass:"row-item__column pl-1 d-flex align-center justify-center",style:(_vm.cellStyle)},[_vm._t(header.value,function(){return [_vm._v("\n          "+_vm._s(_vm.item[header.value])+"\n        ")]})],2)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }