<template>
  <div class="anpr-event">
    <HoverEventThumbnailContainer
      display-position="left"
      :hovered-thumbnail="hoveredThumbnail"
    />
    <div class="d-flex align-center">
      <div class="d-flex">
        <h3 v-if="totalCheckedEvents" class="mr-1">
          {{ checkedEventsSummary }}
        </h3>
        <div class="d-flex align-center mr-1">
          <v-badge
            v-for="item in eventStateLegends"
            :key="item.key"
            bottom
            inline
            left
            :color="item.color"
          >
            <div class="black--text text--lighten-1">{{ item.label }}</div>
          </v-badge>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="mb-1">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              small
              :disabled="totalCheckedEvents === 0"
              v-bind="attrs"
              v-on="on"
            >
              Mark as
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="action in markAsActions"
              :key="action.status"
              :disabled="action.disabled"
              @click="() => updateCheckedItemsStatus(action.status)"
            >
              <v-list-item-title>{{ action.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="d-flex">
      <v-checkbox
        v-model="selectedAll"
        hide-details
        class="resize-checkbox ma-0 mb-1"
      ></v-checkbox>
      <GateReportGenericRowItem
        class="anpr-event__headers"
        :headers="headers"
        :is-headers-row="true"
        :sorted-column="sortedColumn"
        :is-desc="isDesc"
        height="30px"
        @sort="onSort"
      />
    </div>
    <div
      v-if="anprStore.isLoadingEvents"
      class="d-flex w-100 align-center justify-center mt-3"
    >
      <EvercamLoadingAnimation size="Xl" class="mr-2" />
      <span class="e-sm-base"> Fetch events ...</span>
    </div>
    <div
      v-else-if="!sortedAnprEvents.length"
      class="d-flex w-100 justify-center py-4"
    >
      No events to show.
    </div>
    <div
      v-else
      ref="container"
      v-resize-observer="handleEventsContainerResize"
      class="anpr-event__list e-w-full pl-0 pt-0"
      :style="containerStyle"
    >
      <v-lazy
        v-for="event in paginatedEvents"
        :key="event.id"
        :options="{
          threshold: 0.3,
        }"
        :min-height="expandedEventCardsHeights[event.id] || defaultCardHeight"
        :height="expandedEventCardsHeights[event.id] || defaultCardHeight"
        transition="fade-transition"
        class="w-100"
      >
        <AnprEventItem
          v-model="checkedEventsIds"
          :class="`anpr-event-${event.id}`"
          :event="event"
          :timezone="timezone"
          :headers="headers"
          :selected-camera-type="selectedCameraType"
          @event-selected="onEventSelected"
          @height-changed="updateCardContainerHeight"
          @thumbnail-hovered="hoveredThumbnail = $event"
        >
          <AnprEventEditor
            v-if="event.isSelected"
            :event="getFullEventById(event.id)"
            :loading="isSavingEvent && savingEventId === event.id"
            @save-event="saveEvent"
          />
        </AnprEventItem>
      </v-lazy>
    </div>
    <TablePagination
      class="anpr-event__footer"
      :total-items="totalEvents"
      :rows-per-page.sync="rowsPerPage"
      @update-page="currentPage = $event"
    />
  </div>
</template>

<script>
import CustomSort from "@/mixins/customSort"
import GateReportGenericRowItem from "@/components/gateReport/GateReportGenericRowItem"
import AnprEventEditor from "@/components/gateReport/anpr/AnprEventEditor"
import headers from "@/components/gateReport/anpr/anprEventsHeaders"
import AnprEventItem from "@/components/gateReport/anpr/AnprEventItem"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import HoverEventThumbnailContainer from "@/components/gateReport/HoverEventThumbnailContainer"

import { AiApi } from "@evercam/shared/api/aiApi"
import { AnprEventStatus } from "@evercam/shared/types/anpr"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useAnprStore } from "@/stores/anpr"
import TablePagination from "@/components/TablePagination"

export default {
  name: "AnprEventsList",
  components: {
    GateReportGenericRowItem,
    AnprEventEditor,
    AnprEventItem,
    EvercamLoadingAnimation,
    HoverEventThumbnailContainer,
    TablePagination,
  },
  mixins: [CustomSort],
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    selectedTimestamp: {
      type: String,
      default: undefined,
    },
    selectedCameraType: {
      type: String,
      default: "anpr",
    },
  },
  emits: ["save-event", "events-updated", "event-selected"],
  data() {
    return {
      checkedEventsIds: [],
      containerStyle: {},
      headers,
      AnprEventStatus: AnprEventStatus,
      expandedEventCardsHeights: [],
      defaultCardHeight: 54,
      sortFields: [
        {
          key: "captureTime",
          type: "date",
        },
      ],
      sortedColumn: "captureTime",
      isDesc: false,
      isSavingEvent: false,
      savingEventId: null,
      hoveredThumbnail: { isHovered: false, url: false },
      currentPage: 1,
      rowsPerPage: 100,
      eventStateLegends: [
        { label: "False positive", color: "#ffcdd2" },
        { label: "Duplicate", color: "#ffe0b2" },
        { label: "Not Post processed", color: "#e0b3ff" },
      ],
    }
  },
  computed: {
    ...mapStores(useAccountStore, useAnprStore),
    checkedEvents() {
      return this.paginatedEvents.filter((event) =>
        this.checkedEventsIds.includes(event.id)
      )
    },
    hasDuplicates() {
      return this.checkedEvents.some((event) => event.isDuplicate === true)
    },
    hasNoDuplicates() {
      return this.checkedEvents.some((event) => event.isDuplicate === false)
    },
    hasMixedDuplicates() {
      return this.hasDuplicates && this.hasNoDuplicates
    },
    hasPlates() {
      return this.checkedEvents.some((event) => event.isPlate === true)
    },
    hasNoPlates() {
      return this.checkedEvents.some((event) => event.isPlate === false)
    },
    hasMixedPlates() {
      return this.hasPlates && this.hasNoPlates
    },
    markAsActions() {
      return [
        {
          status: AnprEventStatus.IsDuplicate,
          title: "Duplicate",
          value: { isDuplicate: true },
          disabled: this.hasDuplicates || this.hasMixedDuplicates,
        },
        {
          status: AnprEventStatus.NotDuplicate,
          title: "Not Duplicate",
          value: { isDuplicate: false },
          disabled: this.hasNoDuplicates || this.hasMixedDuplicates,
        },
        {
          status: AnprEventStatus.NotPlate,
          title: "False positive",
          value: { isPlate: false },
          disabled: this.hasNoPlates || this.hasMixedPlates,
        },
        {
          status: AnprEventStatus.IsPlate,
          title: "Not False positive",
          value: { isPlate: true },
          disabled: this.hasPlates || this.hasMixedPlates,
        },
      ]
    },
    selectedAll: {
      get() {
        return this.checkedEventsIds.length === this.paginatedEvents.length
      },
      set() {
        this.handleCheckAll()
      },
    },
    computedAnprEvents() {
      return this.events.map((item) => {
        return {
          thumbnailUrl: `${this.$config.public.apiURL}/cameras/${item.cameraex}/anpr/${item.id}/thumbnail?authorization=${this.accountStore.token}`,
          plateNumber: item.plateNumber,
          captureTime: item.captureTime,
          cameraExid: item.cameraex,
          direction: item.direction,
          vehicleType: item.votedVehicleType,
          isPlate: item.isPlate,
          isDuplicate: item.isDuplicate,
          isPostprocessed: item.isPostprocessed,
          isSelected: this.$moment(item.captureTime).isSame(
            this.selectedTimestamp
          ),
          id: item.id,
          metadata: item.metadata?.plateRecognizerMetadata
            ? item.metadata
            : { plateRecognizerMetadata: {} },
        }
      })
    },
    totalCheckedEvents() {
      return this.checkedEventsIds?.length || 0
    },
    totalPaginatedEvents() {
      return this.paginatedEvents?.length || 0
    },
    checkedEventsSummary() {
      return `Selected Events (${this.totalCheckedEvents}/${this.totalPaginatedEvents})`
    },
    totalEvents() {
      return this.sortedAnprEvents?.length || 0
    },
    sortedAnprEvents() {
      return this.customSort(
        this.computedAnprEvents,
        [this.sortedColumn],
        [this.isDesc]
      )
    },
    paginatedEvents() {
      const start = (this.currentPage - 1) * this.rowsPerPage
      const end = start + this.rowsPerPage

      return this.sortedAnprEvents.slice(start, end)
    },
    tableOptions() {
      return {
        currentPage: this.currentPage,
        rowsPerPage: this.rowsPerPage,
        sortedColumn: this.sortedColumn,
        isDesc: this.isDesc,
      }
    },
    timezone() {
      return this.anprStore.selectedProject?.timezone || "Europe/Dublin"
    },
  },
  watch: {
    tableOptions: {
      handler() {
        this.checkedEventsIds = []
      },
      deep: true,
    },
  },
  mounted() {
    this.$addEventListener("keydown", this.onArrowKeyHandler)
    this.$addEventListener("keyup", this.onArrowKeyHandler)
  },
  methods: {
    getFullEventById(id) {
      return this.events.find((event) => event.id === id)
    },
    onEventSelected(eventId) {
      this.$emit("event-selected", eventId)
    },
    handleCheckAll() {
      if (this.checkedEventsIds.length === this.paginatedEvents.length) {
        this.checkedEventsIds = []
      } else {
        this.checkedEventsIds = this.paginatedEvents.map((item) => item.id)
      }
    },
    handleEventsContainerResize() {
      const container = this.$refs.container
      const top = container.getBoundingClientRect().top
      const height = window.innerHeight - top - 50

      this.containerStyle = {
        height: `${height}px`,
      }
    },
    async updateCheckedItemsStatus(action) {
      const { status, title, value } = this.markAsActions.find(
        (item) => item.status === action
      )

      try {
        await AiApi.anpr.bulkUpdateAnprRecords(this.checkedEventsIds, status)

        this.$notifications.success(
          `Events successfully marked as ${title?.toLowerCase()}s`
        )
        this.$emit(
          "events-updated",
          this.checkedEventsIds.map((id) => ({ id, ...value }))
        )
        this.checkedEventsIds = []
      } catch (error) {
        console.error(error)
        const errorMessage = `Failed to mark events as ${title?.toLowerCase()}s`
        this.$notifications.error(errorMessage)
      }
    },
    updateCardContainerHeight({ eventId, height }) {
      this.expandedEventCardsHeights = {
        ...this.expandedEventCardsHeights,
        [eventId]: height,
      }
    },
    onSort({ isDesc, sortedColumn }) {
      this.sortedColumn = sortedColumn
      this.isDesc = isDesc
    },
    async saveEvent(localEvent) {
      this.isSavingEvent = true
      this.savingEventId = localEvent.id
      try {
        await AiApi.anpr.updateAnprEvent(localEvent.id, localEvent)
        this.$emit("events-updated", [localEvent])
        this.$notifications.success("Event updated successfully.")
      } catch (error) {
        this.$notifications.error({ text: "Failed to update event.", error })
      } finally {
        this.isSavingEvent = false
        this.savingEventId = null
      }
    },
    onArrowKeyHandler(e) {
      if (e.type === "keyup" || !["ArrowDown", "ArrowUp"].includes(e.code)) {
        return
      }

      const arrayLength = this.computedAnprEvents?.length - 1
      const isMultipleSelected =
        this.computedAnprEvents.filter((e) => e.isSelected)?.length > 1

      let currentEventIndex =
        e.code === "ArrowDown" && isMultipleSelected
          ? this.computedAnprEvents.findLastIndex((e) => e.isSelected)
          : this.computedAnprEvents.findIndex((e) => e.isSelected)

      currentEventIndex = currentEventIndex > -1 ? currentEventIndex : 0

      const newEventIndex =
        e.code === "ArrowDown"
          ? Math.min(currentEventIndex + 1, arrayLength)
          : Math.max(currentEventIndex - 1, 0)
      const eventId = this.computedAnprEvents[newEventIndex]?.id

      if (eventId && newEventIndex !== currentEventIndex) {
        document
          .querySelector(`.anpr-event-${eventId}`)
          .scrollIntoView({ behavior: "smooth", block: "center" })
        this.onEventSelected(eventId)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@evercam/shared/styles/mixins";
.anpr-event {
  &__list {
    @include custom-scrollbar();
    overflow-y: scroll;
  }
  &__headers {
    background-color: rgb(212, 226, 238);
    border-bottom: 1px solid #e0e0e0;
    margin-right: 10px !important;
  }
  &__footer {
    margin-top: 2px;
    padding: 2px 0px;
    background: rgb(212, 226, 238);
    border-radius: 0.5em;
    box-shadow: 0 5px 16px -13px #000;
    gap: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: center;
    justify-content: end;
  }
}
</style>
