<template>
  <div
    v-if="isHeadersRow"
    class="row-item__header w-100 d-flex"
    :style="{
      'min-height': `${height} !important`,
    }"
  >
    <div
      v-for="header in headers"
      :key="header.value"
      class="pl-1 d-flex align-center justify-start column font-weight-500 caption font-weight-regular"
      :class="{
        'column--sortable': header.sortable,
        'arrow-up': !isDesc,
        'arrow-down': isDesc,
        'is-selected': sortedColumn === header.value,
      }"
      :style="cellStyle"
      @click="onSort(header.value)"
    >
      {{ header.text }}
    </div>
  </div>
  <v-list-item
    v-else
    class="row-item pa-0 caption"
    :class="{
      'row-item--selected': isSelected,
      'row-item--errored': isErrored,
      'row-item--duplicate': isDuplicate,
      'row-item--not-processed': isNotProcessed,
    }"
    :style="{
      'min-height': `${height} !important`,
    }"
    @click="onRowClick"
  >
    <v-list-item-content class="py-0 h-100">
      <v-list-item-title class="h-100 d-flex align-center justify-start">
        <div
          v-for="header in headers"
          :key="header.value"
          class="row-item__column pl-1 d-flex align-center justify-center"
          :style="cellStyle"
        >
          <slot :name="header.value">
            {{ item[header.value] }}
          </slot>
        </div>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "GateReportGenericRowItem",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isErrored: {
      type: Boolean,
      default: false,
    },
    isDuplicate: {
      type: Boolean,
      default: false,
    },
    isNotProcessed: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "48px",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    isHeadersRow: {
      type: Boolean,
      default: false,
    },
    sortedColumn: {
      type: String,
      default: "",
    },
    isDesc: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cellStyle() {
      return {
        width: `${100 / this.headers.length}%`,
      }
    },
  },
  methods: {
    onRowClick() {
      this.$emit("click", this.item)
    },
    onSort(column) {
      this.$emit("sort", {
        isDesc: !this.isDesc,
        sortedColumn: column,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.row-item {
  user-select: text;
  margin: 3px;
  border-radius: 5px;
  background: rgb(232, 244, 253);
  transition: background 0.3s, opacity 0.3s;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 1;
  &:hover {
    background-color: #efffe5;
  }
  &--selected {
    border: 2px solid #1b77d2;
  }
  &--duplicate {
    background-color: #ffe0b2;
    &:hover {
      background-color: #ffedcc;
    }
  }
  &--errored {
    background: #ffcdd2;
    &:hover {
      background-color: #ffd9dd;
    }
  }
  &--not-processed {
    background: #e0b3ff;
    &:hover {
      background-color: #ebccff;
    }
  }
  &__loading-overlay {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
  }

  &__column {
    margin-right: 20px;
    text-align: center;
    white-space: wrap;
  }
  &__header {
    background: #becbd5;
    border-radius: 4px;
    .column {
      white-space: wrap;
      user-select: none;
      &.arrow-up::after {
        content: "▲";
        margin-left: 2px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &.arrow-down::after {
        content: "▼";
        margin-left: 2px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
      &--sortable {
        cursor: pointer;
        &:hover.arrow-up::after,
        &.is-selected.arrow-up::after,
        &:hover.arrow-down::after,
        &.is-selected.arrow-down::after {
          opacity: 1;
        }
      }
    }
  }
}
</style>
